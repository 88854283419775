<template>
    <v-text-field ref="myInput" :filled="isFilled" :value="value" v-on:keyup.enter="enter" @input="handleInput()" :counter="max" maxlength="max" :label="label" :name="name" :rules="formRules"
        outlined dense rounded :prepend-inner-icon="icon" @change="setChange" :hide-details="(isHideDetail||isReadonly) ? true:false"
         :readonly="isReadonly" :class="(isReadonly) ? 'isReadonly':''" 
          
        
         color="#00A551">
    </v-text-field>
</template>
<script>
    export default {
        data() {
            return {
                 formRules: [],
                 content:this.value
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation; 
                let tmp_validate = [];  
                if (validate) {
                     validate.forEach(rule => {
                        switch (rule) {
                            case 'required':
                                tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                            break;
                            case 'email':
                                tmp_validate.push(v => /.+@.+\..+/.test(v) || 'Format E-mail Salah');
                            break
                        }
                    });
                }              
               
               let max = this.max

                if (max) {
                    tmp_validate.push(v => !!v && v.length <= this.max || "Maksimal "+this.max+" Karakter");
                }
                if (this.min) {
                    tmp_validate.push(v => !!v && v.length >= this.min || "Minimal "+this.min+" Karakter");
                }

                 this.formRules = tmp_validate;
            },
            handleInput (e) {
                this.$emit('input', this.$refs.myInput.value)
            },
            enter() {
                this.$emit('enter');
            },
            setChange() {
                this.$emit('setChange');
            },
        },
        mounted() {
           this.setFormRules();
        },
        props: ['label', 'name', 'value','validation','max','min','icon','isFilled','isHideDetail','isReadonly'],
        name: 'formInput',
    }
</script>